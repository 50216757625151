let aboutTitle = document.querySelector(".p-top-about__title");
let fadeInElements = document.querySelectorAll(".js-fade-in");

// Intersection Observerの設定を共通化する関数
function configureIntersectionObserver(
  className,
  threshold = 0,
  rootMargin = "0%"
) {
  return new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(className);
          observer.unobserve(entry.target);
        }
      });
    },
    {
      root: null,
      rootMargin: rootMargin,
      threshold: threshold,
    }
  );
}

// ボーダーとテキストのアニメーション用のObserverを設定
const borderObserve = configureIntersectionObserver("js-active--border");
const textObserve = configureIntersectionObserver("js-active--text");

// フェードインのIntersection Observer
const fadeObserve = configureIntersectionObserver("js-active-fade");

// .js-fade クラスを持つ要素に対して Observer を設定
document
  .querySelectorAll(".js-fade")
  .forEach((element) => fadeObserve.observe(element));

// aboutTitleに対して複数のObserverを設定
textObserve.observe(aboutTitle);
borderObserve.observe(aboutTitle);
